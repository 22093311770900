<template>
    <div class="card card-shadow">
        <div class="card-inner">
            <div class="card-title-group align-start mb-2">
                <div class="card-title">
                    <h6 class="title">Assets By Type</h6>
                </div>
            </div>
            <div v-if="loaded">
              <apexchart :height="'250px'" :options="chartOptions" :series="series"></apexchart>
            </div>
        </div>
    </div><!-- .card -->
</template>

<script>
export default {
    data() {
        return {
          ticketStats: null,
          loaded: false,
          series: [],
          chartOptions: {
            colors: ['#7EB2DD'],
            fill: {
              colors: ['#7EB2DD']
            },
            dataLabels: {
              enabled: false,
              style: {
                colors: ['#7EB2DD']
              }
            },
            labels: [],
            markers: {
              colors: ['#7EB2DD']
            },
            chart: {
              width: 200,
              height: 250,
              type: 'bar'
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            stroke: {
              curve: 'smooth'
            },
            yaxis: {
              title: {
                text: 'Asset Type'
              }
            },
            xaxis: {
              categories:  [],
              title: {
                text: 'Number Of Assets'
              }
            },
            tooltip: {
              x: {
                show:false
              },
            },
          },
        }
    },
    created() {
        this.getStats()
    },
    methods:{
        getStats() {
            this.loaded = false
            this.$http.get('/widgets/Get_AssetsByTypeChart')
            .then((response) => {
                this.ticketStats = response.data
                //let xaxis  = [] 
                let series  = [{name: 'Number of Assets', data: []}]
                response.data.forEach(element => {
                    //xaxis.push(element.year)
                    this.chartOptions.xaxis.categories.push(element.name)
                    series[0].data.push(element.assetCount)
                    //alert(element.year)
                })
                // this.chartOptions.xaxis.categories = response.data.name
                // this.series.data = response.data.assetCount
                //this.chartOptions.xaxis.categories = xaxis
                this.series = series
                this.loaded = true
            })
        }
    }
}
</script>

<style>

</style>
<template>
    <div>
        <div class="row">
            <div class="col-lg-8 col-xxl-8">
                <assetsByTypeChart/>
                <assetsDepreciatedTotals/>
            </div>
            <div class="col-md-4 col-lg-4">
                <router-link :to="{ name: 'assetsmanager'}" class="btn btn-dark btn-block mb-3"><em class="icon ni ni-package-fill mr-1"></em> Asset Manager</router-link>
                <assetsCount/>
                <recentAssetHistory/>
            </div>
        </div>
    </div>
</template>

<script>
import assetsCount from '@/components/widgets/assets/assetsCount.vue'
import recentAssetHistory from '@/components/widgets/assets/recentassethistory.vue'
import assetsByTypeChart from '@/components/widgets/assets/assetsByTypeChart.vue'
import assetsDepreciatedTotals from '@/components/widgets/assets/assetsDepreciatedTotals.vue'

export default {
    components: { assetsCount, recentAssetHistory, assetsByTypeChart, assetsDepreciatedTotals },
    created() {
        this.getView()
    },
    methods: {
        getView() {
            this.$http.get('/ViewAccess/assetdashboard')
            .then(() => {
            })
            .catch(() => {
            })
        },
    }
}
</script>

<style>

</style>
<template>
    <div class="mb-3 card card-shadow">
        <div class="row g-4 card-inner">
            <div class="col-sm-12 col-xxl-12">
                <div class="nk-order-ovwg-data buy">
                    <div class="amount">
                        {{stats.activeCount}} <small class="currenct currency-usd">Active Assets</small>
                    </div>
                    <div class="info">
                        As of
                        <strong>
                            {{ $moment(date).format('LL')}}
                        </strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-xxl-12">
                <div class="nk-order-ovwg-data text-danger border-danger">
                    <div class="amount">
                        {{stats.retiredCount}} <small class="currenct currency-usd">Retired Assets</small>
                    </div>
                    <div class="info">
                        As of
                        <strong>
                            {{ $moment(date).format('LL')}}
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            stats: null,
            date: new Date,
        }
    },
    created() {
        this.getStats()
    },
    methods: {
        getStats() {
            this.$http.get("/widgets/Get_AssetCount/")
            .then((response) => {
                this.stats = response.data;
            })
        }
    }
}
</script>

<style>

</style>
<template>
    <div class="card card-shadow">
        <div class="row card-inner">
            <div class="col-md-7">
                <div class="row">
                    <div class="col-12">
                        <h6>Total Value Of Assets</h6>
                    </div>
                </div>
                <apexchart v-if="loaded" :height="'250px'" :series="series" :options="chartOptions" />
            </div>
            <div class="col-md-5 border-left">
                <div class="row border rounded border-success m-3" style="border-width:2px !important; padding:10px;">
                    <div class="col-4">
                        <h5 class="text-success">£{{ totalStats.assetTotalPurchased }}</h5>
                    </div>
                    <div class="col-8">
                        <h4 class="text-success ">Purchased Value</h4>
                    </div>
                </div>
                <div class="row border rounded border-danger m-3" style="border-width:2px !important; padding:10px;">
                    <div class="col-4">
                        <h5 class="text-danger">£ {{totalStats.assetPerYearDepreciationTotal}}</h5>
                    </div>
                    <div class="col-8">
                        <h4 class="text-danger">Total Depreciation Per Year</h4>
                    </div>
                </div>
                <div class="row border rounded border-info m-3" style="border-width:2px !important; padding:10px;">
                    <div class="col-4">
                        <h5 class="text-info">£{{totalStats.assetCurrentValue}}</h5>
                    </div>
                    <div class="col-8">
                        <h4 class="text-info">Current Asset Value</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            stats: null,
            totalStats: {},
            series: [],
            loaded: false,
            chartOptions: {
                colors: ['#0AC2DE','#FF5C5C','#1EE0AC'],
                fill: {
                    colors: ['#0AC2DE','#FF5C5C','#1EE0AC']
                },
                dataLabels: {
                enabled: false,
                style: {
                    colors: ['#0AC2DE','#FF5C5C','#1EE0AC']
                    }
                },
                labels: [],
                markers: {
                    colors: ['#0AC2DE','#FF5C5C','#1EE0AC']
                },
                chart: {
                    width: 200,
                    height: 250,
                    type: 'line'
                },
                plotOptions: {
                    bar: {
                        horizontal: true
                    }
                },
                stroke: {
                    curve: 'smooth'
                },
                yaxis: {
                    title: {
                        text: 'Value of Assets'
                    }
                },
                xaxis: {
                    categories:  [],
                    title: {
                        text: 'Years'
                    }
                },
                tooltip: {
                    x: {
                        show:false
                    },
                },
            },
        }
    },
    created() {
        this.getAssetTotalsChart()
        this.getAssetTotals()
    },
    methods: {
        getAssetTotalsChart() {
            this.$http.get("/widgets/Get_AssetTotalsChart")
            .then((response) => {
                this.stats = response.data

                let series  = [{name: 'Current Value of Assets', data: []}, {name: 'Total Depreciation of Assets', data: []}, {name: 'Purchased Value', data: []}]
                response.data.forEach(element => {
                    //xaxis.push(element.year)
                    this.chartOptions.xaxis.categories.push(element.year)
                    series[0].data.push(element.depreciatedValue)
                    series[1].data.push(element.accumalatedDepreciation)
                    series[2].data.push(element.total)
                    //alert(element.year)
                })
                this.series = series
                this.series[1].type = 'area'
                this.loaded = true
            })
        },
        getAssetTotals() {
            this.$http.get("/widgets/Get_AssetTotals")
            .then((response) => {
                this.totalStats = response.data
            })
        }
    }
}
</script>

<style>

</style>